
import { Role } from "../Helpers/role";

export const userService = {
    getAll,
    getAllUsernames,
    getAllUserEmails,
    getById,
    getByUsername,
    getByResetPasswordToken,
    getBySessionId,
    register,
    update,
    deleteUser
};

async function getAll() {
    const fullDomain = window.location.protocol + "//" + window.location.host;
    const url = `${fullDomain}/users/getall`;
    var users = [];
    await fetch(url, {
            method: "GET"
        })
        .then(response => response.json())
        .then(result => {
            users = result;
        })
        .catch(e => {
            console.log(e);
        });
    return users;

}

async function getAllUsernames() {
    const fullDomain = window.location.protocol + "//" + window.location.host;
    const url = `${fullDomain}/users/getallusernames`;
    var usernames = [];
    await fetch(url, {
            method: "GET"
        })
        .then(response => response.json())
        .then(result => {
            usernames = result;
        })
        .catch(e => {
            console.log(e);
        });
    return usernames;
}

async function getAllUserEmails() {
    const fullDomain = window.location.protocol + "//" + window.location.host;
    const url = `${fullDomain}/users/getallemails`;
    var useremails = [];
    await fetch(url, {
            method: "GET"
        })
        .then(response => response.json())
        .then(result => {
            useremails = result;
        })
        .catch(e => {
            console.log(e);
        });
    return useremails;
}

async function getById(id) {
    const fullDomain = window.location.protocol + "//" + window.location.host;
    const url = `${fullDomain}/users/${id}`;
    var user = null;
    await fetch(url, {
            method: "GET"
        })
        .then(response => response.json())
        .then(result => {
            user = result;
        })
        .catch(e => {
            console.log(e);
        });
    return user;
}

async function getByUsername(username) {
    const fullDomain = window.location.protocol + "//" + window.location.host;
    const url = `${fullDomain}/users/${username}`;
    var user = null;
    await fetch(url, {
            method: "GET"
        })
        .then(response => response.json())
        .then(result => {
            user = result;
        })
        .catch(e => {
            console.log(e);
        });
    return user;
}

async function getByResetPasswordToken(token) {
    const fullDomain = window.location.protocol + "//" + window.location.host;
    const url = `${fullDomain}/users/getbyresetpasswordtoken/?token=${encodeURIComponent(token)}`;
    var user = null;
    await fetch(url, {
            method: "GET"
        })
        .then(response => response.json())
        .then(result => {
            user = result;
        })
        .catch(e => {
            console.log(e);
        });
    return user;
}

async function getBySessionId(token) {
    const fullDomain = window.location.protocol + "//" + window.location.host;
    const url = `${fullDomain}/users/getbysessionid/?token=${encodeURIComponent(token)}`;
    var user = null;
    await fetch(url, {
            method: "GET"
        })
        .then(response => response.json())
        .then(result => {
            user = result;
        })
        .catch(e => {
            console.log(e);
        });
    return user;
}

async function register(firstname, lastname, email, username, password, requesttoviewphotos, requesttoaddphotos, status) {
    const fullDomain = window.location.protocol + "//" + window.location.host;
    const url = `${fullDomain}/users/register`;

    return fetch(url,
        {
            method: "POST",
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify({
                firstName: firstname,
                lastName: lastname,
                email: email,
                username: username,
                password: password,
                role: Role.User,
                requestToViewPhotos: requesttoviewphotos,
                requestToAddPhotos: requesttoaddphotos,
                status: status
            })
        });

}

async function update(id, firstname, lastname, email, username, password, role, requesttoviewphotos, requesttoaddphotos, oldpassword, status) {
    const fullDomain = window.location.protocol + "//" + window.location.host;
    const url = `${fullDomain}/users/update`;
    const user = {
        id: id,
        firstName: firstname,
        lastName: lastname,
        email: email,
        username: username,
        password: password,
        role: role,
        requestToViewPhotos: requesttoviewphotos,
        requestToAddPhotos: requesttoaddphotos,
        status: status
    };
    const oldPassword = oldpassword;
    return fetch(url,
        {
            method: "POST",
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify({ User: user, OldPassword: oldPassword })
        });

}

async function deleteUser(id) {
    const fullDomain = window.location.protocol + "//" + window.location.host;
    const url = `${fullDomain}/users/delete`;

    return fetch(url,
            {
                method: "POST",
                headers: { 'Content-Type': "application/json" },
                body: JSON.stringify({
                    id: id,
                    firstName: "",
                    lastName: "",
                    email: "email@email.com",
                    username: "username",
                    password: "password",
                    role: Role.User,
                    requestToViewPhotos: false,
                    requestToAddPhotos: false
                })
            });
}
