import { BehaviorSubject } from "rxjs";

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem("currentUser")));

export const authenticationService = {
    login,
    logout,
    logoutLocally,
    resetPassword,
    currentUser: currentUserSubject.asObservable(),
    get currentUserValue() { return currentUserSubject.value }
};

async function login(username, password) {
    const fullDomain = window.location.protocol + "//" + window.location.host;
    const url = fullDomain + "/users/authenticate";
    let status = 0;
    var key = "flasdkjfpoijwfeljaskj";
    var encryptor = require('simple-encryptor')(key);
    try {
        await fetch(url,
            {
                method: "POST",
                headers: { 'Content-Type': "application/json" },
                body: JSON.stringify({ username: username, password: password, email: "email@email.com" })
            })
            .then(async response => {
                    const result = await response.json();
                    if (result) {
                        const currentUser = await JSON.stringify(result);
                        localStorage.setItem("currentUser", currentUser);
                        localStorage.setItem("currentUser2", encryptor.encrypt(username));
                        localStorage.setItem("currentUser3", encryptor.encrypt(password));
                        currentUserSubject.next(result);
                       
                    }
                    status = response.status;
                }
            );
    }
    catch (e) {
        console.log(e);
        return 500;
    }

    return status;
}

async function logoutLocally() {
    localStorage.removeItem("currentUser");
    currentUserSubject.next(null);
}

async function logout() {
    const fullDomain = window.location.protocol + "//" + window.location.host;
    const url = fullDomain + "/users/logout";

    try {
        await fetch(url,
            {
                method: "POST",
                headers: { 'Content-Type': "application/json" },
                body: JSON.stringify({ username: "username", password: "password", email: "email@email.com" })
            });
    } catch (e) {
        console.log(e);
    }

    logoutLocally();
}

async function resetPassword(email) {
    const fullDomain = window.location.protocol + "//" + window.location.host;
    const url = fullDomain + "/users/resetpassword";

    try {
        return await fetch(url,
            {
                method: "POST",
                headers: { 'Content-Type': "application/json" },
                body: JSON.stringify({ username: "username", password: "password", email: email })
            });
    } catch (e) {
        console.log(e);
    }
}
