import React, { Component } from "react";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";

import { authenticationService } from "./components/Services/authentication.service";
import { userService } from "./components/Services/user.service";

import { Role } from "./components/Helpers/role";
import { PrivateRoute } from "./components/Helpers/private-route";

// Import css
import "bootstrap/dist/css/bootstrap.css";
import "./assets/fomantic/dist/semantic.css";
import "./css/pe-icon-7-stroke.css";
import "./css/materialdesignicons.css";
import "./font-awesome/css/font-awesome.css";
import "./css/waves.css";
//import "./css/bootstrap.min.css";
import "./css/magnific-popup.css";
import "./css/vertical.css";
//import "./css/style-two.css"
import "./css/style-clever.css";
import "./css/red.css";
import "./css/style.css";

const Loader = React.lazy(() => import("./components/loader"));
const Gallery = React.lazy(() => import("./components/Shared/gallery"));
const UploadPhotos = React.lazy(() =>
  import("./components/Shared/upload-photos")
);

const UserLogin = React.lazy(() => import("./components/Auth/user-login"));
const Register = React.lazy(() => import("./components/Auth/register"));
const Update = React.lazy(() => import("./components/Auth/update"));
const UpdateAdmin = React.lazy(() => import("./components/Auth/update-admin"));
const ForgetPassword = React.lazy(() =>
  import("./components/Auth/password-forget")
);
const UpdateReset = React.lazy(() => import("./components/Auth/update-reset"));

const AdminPage = React.lazy(() => import("./components/Admin/admin-page"));

const Music = React.lazy(() => import("./components/Shared/music"));

const Error500 = React.lazy(() => import("./components/Errors/error-500"));
const Error404 = React.lazy(() => import("./components/Errors/error-404"));

const Pager = React.lazy(() => import("./components/Shared/pager"));

const AppList = React.lazy(() => import("./app-list"));

class App extends Component {
  static displayName = App.name;

  async componentWillMount() {
    var key = "flasdkjfpoijwfeljaskj";
    var encryptor = require("simple-encryptor")(key);
    const loggedUser = localStorage.getItem("currentUser");
    if (loggedUser) {
      await authenticationService.login(
        encryptor.decrypt(localStorage.getItem("currentUser2")),
        encryptor.decrypt(localStorage.getItem("currentUser3"))
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <Router>
          <React.Suspense fallback={<div>&nbsp;</div>}>
            <Switch>
              <Route exact path="/" component={Loader} />
              <PrivateRoute
                path="/gallery"
                component={Gallery}
                roles={[Role.Admin, Role.UserView, Role.UserAdd]}
              />
              {/* <Route path="/gallery" component={Gallery} /> */}
              <PrivateRoute
                path="/upload-photos"
                component={UploadPhotos}
                roles={[Role.Admin, Role.UserAdd]}
              />
              <PrivateRoute
                path="/music"
                component={Music}
                roles={[Role.Admin, Role.User, Role.UserView, Role.UserAdd]}
              />
              <PrivateRoute
                path="/admin"
                component={AdminPage}
                roles={[Role.Admin]}
              />
              <PrivateRoute
                path="/update"
                component={Update}
                roles={[Role.Admin, Role.User, Role.UserView, Role.UserAdd]}
              />
              <PrivateRoute
                path="/updateadmin"
                component={UpdateAdmin}
                roles={[Role.Admin]}
              />
              <Route path="/register" component={Register} />
              <Route path="/password-forget" component={ForgetPassword} />
              <Route path="/resetpassword" component={UpdateReset} />
              <Route path="/login" component={UserLogin} />
              <Route path="/error500" component={Error500} />
              <Route path="/error404" component={Error404} />

              <Route path="/pager" component={Pager} />
              <Route path="/app" component={AppList} />
            </Switch>
          </React.Suspense>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
